<template>
  <el-container style="height: 100%" @dragenter.native="dragenter">
    <el-header style="padding: 0; height: 40px">
      <div id="navigation">产品信息</div>
    </el-header>

    <el-container style="height: calc(100% - 40px)">
      <div style="padding: 20px 20px 0 20px">
        <el-form :inline="true" ref="dataForm" :model="dataForm" @keyup.enter.native="getDataList(true)">
          <el-form-item prop="suit">
            <el-autocomplete
              v-model="dataForm.suit"
              :fetch-suggestions="(a, b) => queryKey(a, b, 'suit')"
              placeholder="产品系列"
              clearable
            ></el-autocomplete>
          </el-form-item>
          <el-form-item prop="model">
            <el-autocomplete
              v-model="dataForm.model"
              :fetch-suggestions="(a, b) => queryKey(a, b, 'model')"
              placeholder="设备型号"
              clearable
            ></el-autocomplete>
          </el-form-item>
          <el-form-item prop="pn">
            <el-autocomplete v-model="dataForm.pn" :fetch-suggestions="(a, b) => queryKey(a, b, 'pn')" placeholder="PN码" clearable></el-autocomplete>
          </el-form-item>
          <el-form-item>
            <el-button @click="getDataList(true)" type="primary">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="refreshForm()" style="background: rgb(30, 30, 242, 0.1); color: #5053dd">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-main style="padding: 0 20px 20px 20px; overflow: hidden">
        <div style="height: 1px; background: #d4d6d9; opacity: 0.5; margin-bottom: 22px"></div>
        <div class="main">
          <el-table
            v-if="dataList.length"
            :data="dataList"
            v-loading="tabLoding"
            header-cell-class-name="tmc_table_header"
            row-class-name="tmc_table_row"
            style="width: 100%"
            height="calc(100vh - 367px)"
          >
            <el-table-column prop="suit" header-align="center" align="center" label="产品系列"> </el-table-column>
            <el-table-column prop="model" header-align="center" align="center" label="设备型号"> </el-table-column>
            <el-table-column prop="pn" header-align="center" align="center" label="PN码"> </el-table-column>
            <el-table-column prop="permissionName" header-align="center" align="center" show-overflow-tooltip label="功能权限"> </el-table-column>
            <el-table-column prop="comment" header-align="center" align="center" show-overflow-tooltip label="备注"> </el-table-column>
            <el-table-column header-align="center" align="center" width="150" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
                <el-button type="text" size="small" @click="toDelete(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div v-else style="height: 100%; text-align: center">
            <el-image :src="require('@/assets/img/no-data.png')" fit="contain" style="margin: 10% 0 24px 0"> </el-image>
            <div style="font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #73767a">啊哦~这里没有数据呢~</div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <div v-if="dataList.length" style="margin-top: 20px">
          <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </el-footer>
    </el-container>
    <!-- 修改 -->
    <product-info-add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></product-info-add-or-update>
  </el-container>
</template>

<script>
import ProductInfoAddOrUpdate from '@/views/ProductInfoAddOrUpdate.vue'
export default {
  components: { ProductInfoAddOrUpdate },
  name: 'Device',
  data() {
    return {
      dataForm: {
        suit: '',
        model: '',
        pn: ''
      },
      timeOutId: undefined,
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      tabLoding: false,
      dataList: [],
      addOrUpdateVisible: false,
      options: [
        {
          value: 1,
          label: '云盘'
        },
        {
          value: 2,
          label: '智能拍摄集'
        },
        {
          value: 4,
          label: '规则诊断集'
        },
        {
          value: 8,
          label: '视频预览'
        }
      ]
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    toDelete(id) {
      this.$confirm(
        `<div >
          <div class="tmc_confirm_icon"></div>
          <div class="tmc_confirm_title">提示</div> 
          <div class="tmc_confirm_content">确定进行删除操作？
        </div> </div>`,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          center: true,
          customClass: 'tmc_confirm'
        }
      )
        .then(() => {
          this.$http({
            url: this.$http.adornUrl(`/api/v1/production/${id}`),
            method: 'delete',
            params: this.$http.adornParams()
          }).then((res) => {
            if (res.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    getDataList(flag) {
      if (flag) {
        this.pageIndex = 1
      }
      this.tabLoding = true
      this.$http({
        url: this.$http.adornUrl('/api/v1/production/list'),
        method: 'get',
        params: this.$http.adornParams({
          pageSize: this.pageSize,
          pageNum: this.pageIndex,
          suit: this.dataForm.suit,
          model: this.dataForm.model,
          pn: this.dataForm.pn
        })
      })
        .then((res) => {
          if (res.code === 0) {
            const data = res.data || {}
            const list = data.list || []
            list.forEach((item) => {
              const permissionList = []
              for (let i = 0; i < 4; i++) {
                if ((item.permission >> i) & 1) {
                  for (let j = 0; j < this.options.length; j++) {
                    if (this.options[j].value === Math.pow(2, i)) {
                      permissionList.push(this.options[j].label)
                      break
                    }
                  }
                }
              }
              item.permissionName = permissionList.join('；')
            })
            this.dataList = list
            this.totalPage = data.total
          } else {
            this.$message.error(res.msg)
          }
          this.tabLoding = false
        })
        .catch(() => {
          this.tabLoding = false
        })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    queryKey(queryString, cb, type) {
      if (this.timeOutId) {
        clearTimeout(this.timeOutId)
      }

      this.timeOutId = setTimeout(() => {
        let results = []
        let keyObj = {
          suit: queryString
        }
        if (type === 'pn') {
          keyObj = {
            pn: queryString,
            model: this.dataForm.model,
            suit: this.dataForm.suit
          }
        } else if (type === 'model') {
          keyObj = {
            model: queryString,
            suit: this.dataForm.suit,
            likeSuit: 1
          }
        }
        this.$http({
          url: this.$http.adornUrl(`/api/v1/production/${type}`),
          method: 'get',
          params: this.$http.adornParams(keyObj)
        })
          .then((res) => {
            if (res.code === 0) {
              const data = res.data || []
              results = data.map((item) => {
                return { value: item }
              })
            }
            // 调用 callback 返回建议列表的数据
            cb(results)
          })
          .catch(() => {
            cb(results)
          })
      }, 500)
    },
    refreshForm() {
      this.$refs.dataForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  overflow: hidden;
}
#navigation {
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  margin-bottom: 22px;
}
</style>
