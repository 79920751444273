<template>
  <el-dialog :title="dataForm.id ? '修改' : '新增'" :close-on-click-modal="false" :visible.sync="visible" custom-class="tmc-dialog">
    <el-form :model="dataForm" ref="dataForm" :rules="dataRules" @keyup.enter.native="dataFormSubmit()" label-width="90px" autocomplete="off">
      <el-form-item prop="suit" label="产品系列">
        <el-autocomplete
          v-model="dataForm.suit"
          :maxlength="20"
          :fetch-suggestions="(a, b) => queryKey(a, b, 'suit')"
          placeholder="产品系列"
          clearable
          :disabled="disabled"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item prop="model" label="设备型号">
        <el-autocomplete
          v-model="dataForm.model"
          :maxlength="20"
          :fetch-suggestions="(a, b) => queryKey(a, b, 'model')"
          placeholder="设备型号"
          clearable
          :disabled="disabled"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="PN码" prop="pn">
        <el-input v-model="dataForm.pn" maxlength="20" placeholder="PN码" :disabled="disabled"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="comment">
        <el-input v-model="dataForm.comment" placeholder="备注" maxlength="100" type="textarea"></el-input>
      </el-form-item>
      <el-form-item prop="userSpace" label="功能权限">
        <span slot="label">
          功能权限
          <el-tooltip placement="top">
            <div slot="content">用户若绑定了该类型设备，用户权限则会随着产品功能权限的增加而增加，删减则不受影响</div>
            <i class="el-icon-info"></i>
          </el-tooltip>
        </span>
        <div class="inline-item">
          <el-cascader
            ref="cascader"
            v-model="permissionList"
            @change="handleChange"
            :options="options"
            :props="props"
            :show-all-levels="false"
            style="width: 100%"
          ></el-cascader>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import merge from 'lodash/merge'
export default {
  name: 'ProductInfoAddOrUpdate',
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        suit: '',
        model: '',
        pn: '',
        comment: ''
      },
      dataRules: {
        suit: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('产品系列不能为空'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: ['blur', 'change']
          }
        ],
        model: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('设备型号不能为空'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: ['blur', 'change']
          }
        ],
        pn: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('PN码不能为空'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ]
      },
      disabled: false,
      options: [
        {
          value: 1,
          label: '云盘',
          disabled: true
        },
        {
          value: 99,
          label: '智能巡检',
          children: [
            {
              value: 2,
              label: '智能拍摄集',
              parent: 99
            },
            {
              value: 4,
              label: '规则诊断集',
              parent: 99
            }
          ]
        },
        {
          value: 8,
          label: '视频预览'
        }
      ],
      props: { multiple: true },
      permissionList: []
    }
  },
  methods: {
    handleChange() {
      this.$nextTick(() => {
        const checked = this.$refs.cascader.getCheckedNodes(true).map((item) => {
          return item.value
        })

        this.dataForm.permission =
          checked.length > 0
            ? checked.reduce((a, b) => {
                return a + b
              })
            : 0
      })
    },
    init(id) {
      this.dataForm.id = id
      this.dataForm.permission = 1
      this.permissionList = [1]
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/api/v1/production/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then((res) => {
          if (res.code === 0) {
            const data = res.data || {}
            this.dataForm = data
            //权限
            const list = []
            for (let i = 0; i < 4; i++) {
              if ((this.dataForm.permission >> i) & 1) {
                const result = Math.pow(2, i)
                for (let j = 0; j < this.options.length; j++) {
                  const item = this.options[j]
                  // 一级
                  if (item.value === result) {
                    list.push([result])
                    break
                  }
                  // 二级
                  const children = item.children || []
                  let breakFlag = false
                  for (let k = 0; k < children.length; k++) {
                    if (children[k].value === result) {
                      list.push([children[k].parent, result])
                      breakFlag = true
                      break
                    }
                  }
                  if (breakFlag) {
                    break
                  }
                }
              }
            }
            this.permissionList = list

            this.$http({
              url: this.$http.adornUrl(`/api/v1/production/check/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then((res) => {
              if (res.code === 0) {
                this.disabled = res.data === 0
                this.visible = true
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        this.disabled = false
        this.visible = true
        this.$nextTick(() => {
          this.$refs.dataForm.resetFields()
        })
      }
    },
    queryKey(queryString, cb, type) {
      if (this.timeOutId) {
        clearTimeout(this.timeOutId)
      }

      this.timeOutId = setTimeout(() => {
        let results = []
        let keyObj = {
          suit: queryString
        }
        if (type === 'model') {
          keyObj = {
            model: queryString,
            suit: this.dataForm.suit,
            likeSuit: 0
          }
        }
        this.$http({
          url: this.$http.adornUrl(`/api/v1/production/${type}`),
          method: 'get',
          params: this.$http.adornParams(keyObj)
        })
          .then((res) => {
            if (res.code === 0) {
              const data = res.data || []
              results = data.map((item) => {
                return { value: item }
              })
            }
            // 调用 callback 返回建议列表的数据
            cb(results)
          })
          .catch(() => {
            cb(results)
          })
      }, 500)
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl('/api/v1/production/save'),
            method: 'post',
            data: this.$http.adornData(merge({}, this.dataForm, { suit: this.dataForm.suit.trim() }))
          }).then((res) => {
            if (res && res.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>
